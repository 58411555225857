import React from "react";
import "./EventCarouselCard.scss";

const EventCarouselCard = ({
  small,
  medium,
  large,
  imageTile,
  title,
  description,
  imageLink,
  imageAlt,
}) => {
  const size = large ? "large" : small ? "small" : "medium";

  return (
    <div className={`EventCarouselCard ${size}`}>
      <div className="image-section">
        <img
          className="card-image"
          src={`${window.location.origin}${imageLink}`}
          alt={imageAlt}
        />
        <div className="image-filter"></div>
        <div className="image-title-bg"></div>
        <div className="image-title">{imageTile}</div>
      </div>

      {/* <div className="icons-section"></div> */}

      <div className="detail-section">
        <div className="detail-title">{title}</div>
        <div className="detail-desc">{description}</div>
      </div>
    </div>
  );
};

export default EventCarouselCard;
