import "./ContactUs.scss";
import React from "react";
import ContactUsForm from "../components/contactUs/ContactUsForm";
import Container from "@mui/material/Container";
import ContactUsSocialMedia from "../components/contactUs/ContactUsSocialMedia";

const ContactUs = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 0.8,
    },
  };

  const pageTransitions = {
    type: "linear",
    ease: "anticipate",
    duration: 1,
  };

  return (
    <div
      className="Team"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransitions}
    >
      <div className="blur-layer">
        <div className="contact-us-top-slider">
          <div className="conatct-us-top-img-container">
            <img src="/images/contactus/bg.jpg" alt="contact-us-slider"></img>
          </div>

          <div className="contact-us-top-content">
            <div className="contact-us-top-title" data-aos="fade-left">
              Connect With SEDS Mora
            </div>
            <div className="contact-us-top-sub-title" data-aos="fade-right">
              Connect with us to explore the limitless universe
            </div>
          </div>
        </div>

        <div className="contact-us-message-area" data-aos="fade-left">
          <div className="contactus-title">Message us</div>
          <ContactUsForm />
          <div className="contactus-subtitle">
            Or <br /> E-mail us
            <br />{" "}
            <a href="mailto:sedsmora@gmail.com" className="contactus-mail">
              <u>sedsmora@gmail.com</u>{" "}
            </a>
          </div>
        </div>

        <div className="contact-us-social-media">
          <div className="contactus-title">
            Connect via our social media platforms
          </div>
          <ContactUsSocialMedia />
        </div>

        <div className="contact-us-location" data-aos="fade-right">
          <div className="contactus-title">Locate us</div>
          <Container maxWidth="md">
            <div className="contact-us-map">
              <div className="contactus-gmap">
                <iframe
                  src="https://maps.google.com/maps?q=university%20of%20Moratuwa&t=&z=15&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  title="SEDS Mora location"
                ></iframe>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
