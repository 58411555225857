import React from "react";
import "./Team.scss";
import { ExTeam } from "../components/aboutus/OurTeamData";
import { LwTeam } from "../components/aboutus/OurTeamData";
import BoardMemberTile from "../components/aboutus/BoardMemberTile";

const Team = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 0.8,
    },
  };

  const pageTransitions = {
    type: "linear",
    ease: "anticipate",
    duration: 1,
  };

  return (
    <div
      className="Team"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransitions}
    >
      <div className="blur-layer team-blur-layer">
        <div className="main-title about-main-title about-title">Our Team</div>

        <div className="about-sub-title small">Executive Board 22/23</div>
        <div className="board-member-list">
          {ExTeam.map((member, index) => {
            return <BoardMemberTile {...member} key={index} />;
          })}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="about-sub-title small">Committee 22/23</div>
        <div className="board-member-list">
          {LwTeam.map((member, index) => {
            
            return <BoardMemberTile {...member} key={index} />;
          })}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Team;
