import "./App.scss";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Homepage from "./pages/Homepage";
import ConnectUs from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import Insights from "./pages/Insights";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import Aos from "aos";
import "aos/dist/aos.css";
import Blogs from "./pages/Blogs";
import ContactUs from "./pages/ContactUs";
import Eventpage from "./pages/EventPage";
import EventView from "./pages/EventView";

const App = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);

  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <AnimatePresence>
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/events" component={Eventpage} />
          <Route path="/eventview/:id" component={EventView} />
          <Route path="/insights" component={Insights} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/about" component={AboutUs} />
          <Route path="/team" component={Team} />
        </Switch>
      </AnimatePresence>
      <ConnectUs />
    </div>
  );
};

export default App;
