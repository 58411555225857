import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { GrLinkedin } from "react-icons/gr";
import { FaTelegram } from "react-icons/fa";
import { MdGroup } from "react-icons/md";
import "./ContactUsSocialMedia.scss";

const ContactUsSocialMedia = () => {
  const youTubeUrl = "https://www.youtube.com/c/SEDSMora";
  const fbUrl = "https://www.facebook.com/sedsmora";
  const instaUrl = "https://www.instagram.com/seds_mora";
  const linkedInUrl = "https://www.linkedin.com/company/seds-mora";
  const fbGroupUrl = "https://www.facebook.com/groups/874395793094110";
  const teleUrl = "https://t.me/joinchat/pPKunLzo8VVmOTZl";

  return (
    <div>
      <div className="social-icon-row">
        <div className="social-icon-group" data-aos="fade-left">
          <div className="social-icon-sub-title">Watch our videos</div>
          <div className="contactus-social-icon-section">
            <a href={youTubeUrl} target="_blank" rel="noreferrer">
              <div className="social-icon" title="Youtube">
                <FaYoutube />
              </div>
            </a>
          </div>
        </div>

        <div className="social-icon-group" data-aos="fade-left">
          <div className="social-icon-sub-title">To be up to date with us </div>
          <div className="contactus-social-icon-section">
            <a href={fbUrl} target="_blank" rel="noreferrer">
              <div className="social-icon" title="Facebook">
                <FaFacebookSquare />
              </div>
            </a>

            <a href={instaUrl} target="_blank" rel="noreferrer">
              <div className="social-icon" title="Instagram">
                <FaInstagram />
              </div>
            </a>

            <a href={linkedInUrl} target="_blank" rel="noreferrer">
              <div className="social-icon" title="LinkedIn">
                <GrLinkedin />
              </div>
            </a>
          </div>
        </div>

        <div className="social-icon-group" data-aos="fade-left">
          <div className="social-icon-sub-title">Join our groups </div>
          <div className="contactus-social-icon-section">
            <a href={fbGroupUrl} target="_blank" rel="noreferrer">
              <div className="social-icon" title="Facebook Group">
                <MdGroup />
              </div>
            </a>

            <a href={teleUrl} target="_blank" rel="noreferrer">
              <div className="social-icon" title="Telegram Group">
                <FaTelegram />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSocialMedia;
