import React from "react";
import "./HomeAbout.scss";

const HomeAbout = () => {
  return (
    <div className="home-about-section">
      <div className="main-title" data-aos="fade-left">
        Who are we
      </div>

      <div className="description" data-aos="fade-right">
        It's through curiosity and looking at opportunities in new ways that
        we've always mapped our path. Hence we, SEDS Mora bring enthusiasts with
        quizzical minds from every corner of the country together and build a
        platform for their curiosity about space, space technology, astronomy as
        well as cosmology.SEDS Mora guides you to dive into the mysterious
        universe to unveil the hidden secrets through an exhilarating journey
        where you can ask, learn and grow. SEDS Mora provides you the
        opportunities to expand your knowledge about the unrestricted universe
        with webinar and discussion sessions, QnA platforms, practical
        workshops, observation camps along with national and international
        competitions. Throughout the way we came so far we, SEDS Mora always
        ensure the highest quality of the work we do and we will make every
        effort for it in future as well.
      </div>
    </div>
  );
};

export default HomeAbout;
