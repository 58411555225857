import { useState } from "react";
import { Button } from "../button/Button";
import EventCards from "./EventCards";
// import Select from '@mui/material/Select'

const EventFilter = () => {
  const [eventYear, setEventYear] = useState("all");
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleSelections = (filter) => {
    const isSelected = selectedFilters.includes(filter);
    const newFilter = isSelected
      ? selectedFilters.filter((currFilter) => currFilter != filter)
      : [...selectedFilters, filter];
    setSelectedFilters(newFilter);
  };

  const handleChange = (event) => {
    setEventYear(event.target.value);
  };

  return (
    <div className="container-event-board">
      <div className="container-filter-btns">
        <div className="filter-year">
          <select
            value={eventYear}
            className="event-year-list"
            onChange={handleChange}
          >
            <option className="year-list" value="all">
              All
            </option>
            <option className="year-list" value="2023">
              2023
            </option>
            <option className="year-list" value="2021">
              2021
            </option>
            <option className="year-list" value="2020">
              2020
            </option>
            <option className="year-list" value="2019">
              2019
            </option>
          </select>
        </div>
        <div className="vr-line"></div>
        <div className="filter-other">
          <Button
            buttonStyle="btn--outline"
            buttonColor={
              selectedFilters.includes("webinar")
                ? "light-green"
                : "transparent"
            }
            onClick={() => handleSelections("webinar")}
          >
            Webinar
          </Button>
          <Button
            buttonStyle="btn--outline"
            buttonColor={
              selectedFilters.includes("live") ? "light-green" : "transparent"
            }
            onClick={() => handleSelections("live")}
          >
            Live Events
          </Button>
          <Button
            buttonStyle="btn--outline"
            buttonColor={
              selectedFilters.includes("physical")
                ? "light-green"
                : "transparent"
            }
            onClick={() => handleSelections("physical")}
          >
            Physical Events
          </Button>
          <Button
            buttonStyle="btn--outline"
            buttonColor={
              selectedFilters.includes("competition")
                ? "light-green"
                : "transparent"
            }
            onClick={() => handleSelections("competition")}
          >Competitions
          </Button>
        </div>
      </div>

      <EventCards filters={selectedFilters} year={eventYear} />
    </div>
  );
};

export default EventFilter;
