import { Link } from "react-router-dom";
import { CardData } from "../HomeEvents/CardData";
import { Hinge } from "react-awesome-reveal";
// import HeadShake from "react-reveal/HeadShake";

const EventCards = ({ filters, year }) => {
  const filterArray = () => {
    if (year === "all") {
      if (filters.length === 0) {
        return CardData;
      } else {
        return CardData.filter((data) =>
          data.category.some((filter) => filters.indexOf(filter) >= 0)
        );
      }
    } else {
      if (filters.length === 0) {
        return CardData.filter((data) => data.year == year);
      } else {
        return CardData.filter(
          (data) =>
            data.year === year &&
            data.category.some((filter) => filters.indexOf(filter) >= 0)
        );
      }
    }
  };

  return (
    <div className="container-event-cards">
      {filterArray().length > 0 ? (
        filterArray().map((data, index) => {
          return (
            <Link
              to={{ pathname: `/eventview/${data.id}`, state: data.id }}
              key={index}
            >
              <div className="eventboard-card" data-aos="fade-up" key={data.id}>
                <div className="eventboard-card-img">
                  <img
                    src={`${window.location.origin}${data.imageLink}`}
                    alt={data.imgAlt}
                  />
                </div>
                <div className="eventboard-card-info">
                  <p>
                    <b>{data.title}</b>
                  </p>
                </div>
              </div>
            </Link>
          );
        })
      ) : (
        <Hinge>
          <div className="empty-events">Sorry, No results found...</div>
        </Hinge>
      )}
    </div>
  );
};

export default EventCards;
