import React, { useState, useEffect } from "react";
import { SliderData } from "./SliderData";
import "./ImageSlider.scss";
import SliderGuides from "./SliderGuides";
import SliderContent from "./SliderContent";

const ImageSlilder = ({ viewHeight, TimeInterval }) => {
  const [current, setcurrent] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [autoRun, setAutoRun] = useState(true);
  const length = SliderData.length;

  const nextSlide = () => {
    setPrevious(current);
    setcurrent(current === length - 1 ? 0 : current + 1);
  };

  const changeCurrent = (newCurrent) => {
    setcurrent(newCurrent);
  };

  useEffect(() => {
    setTimeout(() => {
      nextSlide();
      setAutoRun(!autoRun);
    }, TimeInterval);
    return null;
  }, [autoRun]);

  if (!Array.isArray(SliderData) || SliderData.length <= 0) {
    return null;
  }

  return (
    <div className={`image-slider ${viewHeight}`}>
      {SliderData.map((slide, index) => {
        return (
          <div className={"image-slide active"} key={index}>
            {index === previous && (
              <img
                key={slide.image}
                src={slide.image}
                alt="wallpaper"
                className="slider-image"
              />
            )}
          </div>
        );
      })}
      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? "image-slide active" : "image-slide"}
            key={index}
          >
            {index === current && (
              <img
                key={slide.image}
                src={slide.image}
                alt="wallpaper"
                className="slider-image"
              />
            )}
          </div>
        );
      })}
      <div className="slider-background-filter"></div>
      <SliderContent />
      <SliderGuides current={current} changeCurrent={changeCurrent} />
    </div>
  );
};

export default ImageSlilder;
