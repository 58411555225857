import React, { useState, useRef } from "react";
import "./ContactUsForm.scss";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import emailjs from "emailjs-com";
import Alert from "@mui/material/Alert";

const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [number, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();
  const [emailSucces, setEmailSucces] = useState(false);
  const [emailError, setEmailError] = useState(false);

  function sendemail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_SedsMora",
        "template_sedsMoraWeb",
        form.current,
        "w4SCR4hINHBtBhxKE"
      )
      .then(
        (result) => {
          setName("");
          setContact("");
          setEmail("");
          setSubject("");
          setMessage("");

          if (result.text === "OK") {
            setEmailSucces(true);
          } else {
            setEmailError(true);
          }
        },
        (error) => {
          setEmailError(true);
        }
      );

    setTimeout(function () {
      setEmailError(false);
      setEmailSucces(false);
    }, 10000);
  }

  return (
    <Container maxWidth="md">
      <form ref={form} onSubmit={sendemail}>
        <div className="form-container">
          <Grid container direction={"row"} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <div className="form-input-div">
                <div className="form-input-label">Your name * : </div>
                <input
                  type="text"
                  placeholder="name"
                  name="senderName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="form-input-div">
                <div className="form-input-label">Your contact number : </div>
                <input
                  type="text"
                  placeholder="contact number"
                  name="contactNumber"
                  value={number}
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>

          <div className="form-input-div">
            <div className="form-input-label">Your e-mail * : </div>
            <input
              type="email"
              placeholder="e-mail"
              name="senderEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-input-div">
            <div className="form-input-label">Email subject * : </div>
            <input
              type="text"
              placeholder="subject"
              name="emailSubject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>

          <div className="form-input-div">
            <div className="form-input-label">Message * : </div>
            <textarea
              type="text"
              placeholder="Type your message here..."
              name="emailBody"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <Grid container justifyContent={"center"} alignItems={"center"}>
            <div className="submit-btn">
              <input type="submit" value="Send message" />
            </div>
          </Grid>
        </div>
      </form>

      <Grid container justifyContent={"center"} alignItems={"center"}>
        {emailError ? (
          <Grid item sm={8}>
            <Alert variant="filled" severity="error">
              There is an error while submitting your email. Try again later!
            </Alert>
          </Grid>
        ) : (
          <></>
        )}

        {emailSucces ? (
          <Grid item sm={8}>
            <Alert variant="filled" severity="success">
              Email submitted successfully. We will reach you soon.
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  );
};

export default ContactUsForm;
