import React from "react";
import "./Footer.scss";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { MdGroup } from "react-icons/md";
import { GrLinkedin } from "react-icons/gr";
import logo from "../../Images/sedsmoralogo-white.png";
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="row">
        {/* Contact Details Column */}
        <div className="columns">
          <div className="column1">
            {/* logo */}
            <div className="logo">
              <img src={logo} alt="Logo" className="footer-logo" />
            </div>

            {/* address */}
            <div className="address">
              <p>
                Bandaranayake Mawatha, <br />
                10400 Katubedda, <br /> Sri Lanka.
              </p>
            </div>

            {/* location */}
            <div className="locate">
              <p>Locate Us :</p>
              <i className="fas fa-map-marker-alt"></i> &nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://g.page/moratuwauni?share"
              >
                Open Google Map
              </a>
            </div>

            {/* Contcat no */}
            <div className="contact">
              <p>Contact Us:</p>
              <i className="fas fa-phone"></i> &nbsp;
              <a href="tel:+94778444135">+94 76 671 8882</a>
            </div>

            {/* Email */}
            <div className="message">
              <p>Email Us:</p>
              <i className="fas fa-envelope"></i> &nbsp;
              <a href="mailto:sedsmora@gmail.com">sedsmora@gmail.com</a>
            </div>
          </div>
        </div>

        {/* Intro Column */}
        <div className="columns">
          <div className="column2">
            <h3>SEDS Mora</h3>
            <p>
              Students for the Exploration and Development of Space or{" "}
              <b>SEDS</b> is an independent, student-based international
              organization which promotes the exploration and development of
              space. <b>SEDS Mora</b> is the SEDS chapter in University of
              Moratuwa, Sri Lanka.
            </p>
          </div>
        </div>

        {/* Navigate Column */}
        <div className="columns">
          <div className="column3">
            <h3>Navigate To</h3>

            {/* Navigate list */}
            <ul>
              <li className="nav-item">
                <NavLink to="/" className="nav-links ">
                  Home
                  <br />
                  <br />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/insights" className="nav-links ">
                  Insights
                  <br />
                  <br />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/events" className="nav-links ">
                  Events
                  <br />
                  <br />
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/blogs" className="nav-links ">
                  Blog
                  <br />
                  <br />
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink to="/about" className="nav-links ">
                  About Us
                  <br />
                  <br />
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/team" className="nav-links ">
                  Team
                  <br />
                  <br />
                </NavLink>
              </li>
              <li className="nav-item">
                <Link to="/contactus" className="nav-links ">
                  Contact Us
                  <br />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Social media area */}
      <div className=" about-title">Connect With Us</div>
      <div className="social-icon-section">
        {/* Facebook page */}
        <a
          href="https://www.facebook.com/sedsmora"
          target="_blank"
          rel="noreferrer"
          className="social-links"
        >
          <div className="footer-social-icon">
            <FaFacebookSquare />
          </div>
        </a>

        {/* Instagram */}
        <a
          href=" https://www.instagram.com/seds_mora"
          target="_blank"
          className="social-links"
        >
          <div className="footer-social-icon">
            <FaInstagram />
          </div>
        </a>

        {/* Linked in */}
        <a
          href=" https://www.linkedin.com/company/seds-mora"
          target="_blank"
          className="social-links"
        >
          <div className="footer-social-icon">
            <GrLinkedin className="linkedin-icon" />
          </div>
        </a>

        {/* You tube */}
        <a
          href="https://www.youtube.com/c/SEDSMora/"
          target="_blank"
          rel="noreferrer"
          className="social-links"
        >
          <div className="footer-social-icon">
            <FaYoutube />
          </div>
        </a>

        {/* Telegram */}
        <a
          href="https://t.me/joinchat/pPKunLzo8VVmOTZl"
          target="_blank"
          rel="noreferrer"
          className="social-links"
        >
          <div className="footer-social-icon">
            <FaTelegramPlane />
          </div>
        </a>

        {/* Facebook group */}
        <a
          href="https://www.facebook.com/groups/874395793094110"
          target="_blank"
          rel="noreferrer"
          className="social-links"
        >
          <div className="footer-social-icon">
            <MdGroup />
          </div>
        </a>
      </div>

      {/* Copy right */}
      <div className="rights-text">
        © 2023 SEDS Mora | University of Moratuwa. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
