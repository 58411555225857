import React from "react";
import { CardData } from "../components/HomeEvents/CardData";
import { Zoom } from "react-awesome-reveal";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "./EventView.scss";

const EventView = (props) => {
  const { id } = props.match.params;
  const pageId = CardData.findIndex((obj) => {
    return obj.id == id;
  });

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 0.8,
    },
  };

  const pageTransitions = {
    type: "linear",
    ease: "anticipate",
    duration: 1,
  };

  return (
    <div
      className="EventView"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransitions}
    >
      <div className="blur-layer eventview-blur-layer">
        <div className="main-title ">{CardData[pageId].title}</div>
        <div className="event-description">
          {CardData[pageId].longDescription}
        </div>

        {/* --------- Image Gallery --------- */}
        <div className="container-images">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              {CardData[pageId].photoLinks.map((image, index) => (
                <Zoom>
                  <img
                    className="event-img"
                    key={index}
                    src={image}
                    style={{ width: "100%", display: "block" }}
                    alt=""
                  />
                </Zoom>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </div>
  );
};

export default EventView;
