export const SliderData = [
  {
    image: '/images/carouselimages/carousel1.jpg'
  },
  {
    image: '/images/carouselimages/carousel2.jpg'  
  },
  {
    image: '/images/carouselimages/carousel3.jpg'  
  },
  {
    image: '/images/carouselimages/carousel4.jpg'  
  },
  {
    image: '/images/carouselimages/carousel5.jpg'  
  },
]