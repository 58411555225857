export const InsightData = [
  {
    title: "Aurigid meteor shower",
    description:
      "The Aurigid meteor shower will be active from 28 September to 5 September, producing its peak rate of meteors around 1 September. The Aurigid meteorite shower will produce 30-50 meteors per hour. The activity is expected to be weak this year, and will reach its peak on September 1. The Aurigids favor the Northern Hemisphere, where their radiant is high above the horizon. In the Southern Hemisphere, it is visible 1-2 hours before sunrise, near the skyline.",
    sinhalaDescription:
      "Aurigid උල්කාපාත වර්ෂාව අගෝස්තු 28 සිට සැප්තැම්බර් 5 දක්වා සක්‍රීය වන අතර එහි උපරිම වේගය සැප්තැම්බර් 1 වනදා පමණ නිපදවයි. Aurigid උල්කාපාත වර්ෂාව පැයකට උල්කාපාත 30-50 ක් නිපදවනු ඇත. මෙම වසරේ ක්‍රියාකාරකම් දුර්වල වනු ඇතැයි අපේක්ෂා කරන අතර සැප්තැම්බර් 1 වන දින එහි උච්චතම අවස්ථාවට ළඟා වනු ඇත. Aurigids උතුරු අර්ධගෝලයට අනුග්‍රහය දක්වයි, ඔවුන්ගේ විකිරණ ක්ෂිතිජයට ඉහළින් පවතී. දක්ෂිණ අර්ධගෝලයේ, එය හිරු උදාවට පැය 1-2 කට පෙර, අහස ආසන්නයේ දිස්වේ.",
    date: "September 01, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "01",
    icon: "falling-star", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-01.png",
    toolTip: "© theskylive.com",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Falcon 9 : Starlink 4-20 & Sherpa-LTC2",
    description:
      "A SpaceX Falcon 9 rocket will launch with another batch of Starlink internet satellites. This mission is also expected to launch Spaceflight's propulsive Sherpa-LTC2 space tug, which will climb into a higher orbit with Boeing's Varuna Technology Demonstration Mission, a pathfinder for a planned constellation of broadband satellites. The Varuna demo mission is a hosted payload on the Sherpa-LTC2 orbital transfer vehicle. The Falcon 9's first stage booster will land on a drone ship in the Atlantic Ocean.",
    sinhalaDescription:
      "SpaceX Falcon 9 රොකට්ටුවක් තවත් Starlink අන්තර්ජාල චන්ද්‍රිකා කණ්ඩායමක් සමඟ දියත් කරනු ඇත. මෙම මෙහෙයුම මගින් Spaceflight හි ප්‍රචාලක Sherpa-LTC2 අභ්‍යවකාශ කඹය දියත් කිරීමටද අපේක්ෂා කරන අතර, එය Boing's Varuna Technology Demonstration Mission සමඟින් ඉහළ කක්ෂයකට නැඟීමට නියමිත අතර, එය සැලසුම් කර ඇති බ්‍රෝඩ්බෑන්ඩ් චන්ද්‍රිකා සමූහයක් සඳහා මගපෙන්වන්නකි. වරුණ ආදර්ශන මෙහෙයුම Sherpa-LTC2 කක්ෂ මාරු වාහනයේ ධාරක පැටවීමකි.  Falcon 9 හි පළමු අදියර බූස්ටරය අත්ලාන්තික් සාගරයේ ඩ්‍රෝන් නෞකාවකට ගොඩබසිනු ඇත.",
    date: "September 04, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "04",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-04.jpg",
    toolTip: "© space.skyrocket.de",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Ariane 5 : Eutelsat Konnect VHTS",
    description:
      "Arianespace will use an Ariane 5 ECA rocket, designated VA258, to launch the Eutelsat Konnect VHTS communications satellite. Owned by Eutelsat and built by Thales Alenia Space, Eutelsat Konnect VHTS will provide fixed broadband and in-flight connectivity services over Europe.",
    sinhalaDescription:
      "Eutelsat Konnect VHTS සන්නිවේදන චන්ද්‍රිකාව දියත් කිරීමට Arianespace විසින් VA258 ලෙස නම් කරන ලද Ariane 5 ECA රොකට්ටුවක් භාවිතා කරනු ඇත. Eutelsat සතු සහ Thales Alenia Space විසින් ගොඩනගා ඇති Eutelsat Konnect VHTS යුරෝපය පුරා ස්ථාවර බ්‍රෝඩ්බෑන්ඩ් සහ පියාසර සම්බන්ධතා සේවා සපයනු ඇත.",
    date: "September 06, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "06",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-06.jpg",
    toolTip: "© thalesgroup.com",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "ε-Perseid meteor shower 2022",
    description:
      "The September ε-Perseid meteor shower will be active from 5 September to 21 September, producing its peak rate of meteors around 9 September.",
    sinhalaDescription:
      "ε-Perseid උල්කාපාත වර්ෂාව සැප්තැම්බර් 5 සිට සැප්තැම්බර් 21 දක්වා සක්‍රීයව පවතින අතර සැප්තැම්බර් 9 පමණ උපරිම සක්‍රියතාවයට පත් වේ",
    date: "September 09, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "09",
    icon: "falling-star", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-09.jpg",
    toolTip: "© uis.edu",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Falcon 9 : Starlink 4-2 & BlueWalker 3",
    description:
      "A SpaceX Falcon 9 rocket will launch with another batch of Starlink internet satellites. The BlueWalker 3 test satellite for AST SpaceMobile's planned space-based cellular broadband network is a ridshare payload on this mission. The Falcon 9's first stage booster will land on a drone ship in the Atlantic Ocean.",
    sinhalaDescription:
      "SpaceX Falcon 9 රොකට්ටුවක් තවත් Starlink අන්තර්ජාල චන්ද්‍රිකා කණ්ඩායමක් සමඟ දියත් කරනු ඇත. AST SpaceMobile හි සැලසුම් කර ඇති අභ්‍යවකාශ පාදක සෙලියුලර් බ්‍රෝඩ්බෑන්ඩ් ජාලය සඳහා වන BlueWalker 3 පරීක්ෂණ චන්ද්‍රිකාව මෙම මෙහෙයුමේ රිඩ්ෂෙයාර් ගෙවීමකි. Falcon 9 හි පළමු අදියර බූස්ටරය අත්ලාන්තික් සාගරයේ ඩ්‍රෝන් නෞකාවකට ගොඩබසිනු ඇත.",
    date: "September 10, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "10",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-10-1.jpg",
    toolTip: "© satellitetoday.com",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Full Moon",
    description:
      "The Moon will reach full phase. At this time of the month, it is visible for much of the night, rising at around dusk and setting at around dawn.",
    sinhalaDescription:
      "චන්ද්‍රයා සම්පූර්ණ අදියර කරා ළඟා වනු ඇත. සැප්තැම්බර් මාසයේ මෙම කාලය තුළ, එය රාත්‍රියේ වැඩි කාලයක් දක්නට ලැබේ, සවස් වන විට නැඟී අලුයම පමණ බැස යයි.",
    date: "September 10, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "10",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-10-2.jpg",
    toolTip: "© in-the-sky.org",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Conjunction of the Moon and Jupiter",
    description:
      "The Moon and Jupiter will share the same right ascension, with the Moon passing 1°48' to the south of Jupiter. The Moon will be 15 days old.",
    sinhalaDescription:
      "චන්ද්‍රයා සහ බ්‍රහස්පති එකම දකුණු ආරෝහණය බෙදා ගනු ඇත, චන්ද්‍රයා බ්‍රහස්පතිට දකුණින් 1°48' පසු කරයි.  චන්ද්‍රයා දින 15 ක් වයසැති වනු ඇත.",
    date: "September 11, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "11",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-11-1.png",
    toolTip: "© in-the-sky.org",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Close approach of the Moon and Jupiter",
    description:
      "The Moon and Jupiter will make a close approach, passing within 1°36' of each other.",
    sinhalaDescription:
      "චන්ද්‍රයා සහ බ්‍රහස්පති එකිනෙකාගෙන් 1°36' ඇතුළත පසුකරමින් සමීප ප්‍රවේශයක් සිදු කරනු ඇත.",
    date: "September 11, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "11",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-11-2.jpg",
    toolTip: "© starwalk.space",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Alpha : Multi-payload",
    description:
      "A Firefly Alpha rocket will launch on its second test flight with a rideshare payload consisting of multiple unspecified small satellites. Delayed from May.",
    sinhalaDescription:
      "ෆයර්ෆ්ලයි ඇල්ෆා රොකට්ටුවක් එහි දෙවන පරීක්ෂණ පියාසැරියේදී නිශ්චිතව දක්වා නොමැති කුඩා චන්ද්‍රිකා කිහිපයකින් සමන්විත ධාවන කොටස් ගෙවීමක් සමඟ දියත් කරනු ඇත. මැයි සිට ප්‍රමාදයි.",
    date: "NET September 11, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "NET 11",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-11-net.jpg",
    toolTip: "© wikimedia.org",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Close approach of the Moon and Uranus",
    description:
      "The Moon and Uranus will make a close approach, passing within a mere 44.1 arcminutes of each other. The Moon will be 18 days old. The Moon will be at magnitude -12.3; and Uranus will be at mag 5.7. Both objects will lie in the constellation Aries.",
    sinhalaDescription:
      "චන්ද්‍රයා සහ යුරේනස් එකිනෙකාට චාප මිනිත්තු 44.1ක් ඇතුළත ගමන් කරමින් සමීප ප්‍රවේශයක් සිදු කරනු ඇත.  චන්ද්‍රයා දින 18 ක් වයසැති වනු ඇත.  සඳ විශාලත්වය -12.3;  සහ යුරේනස් මැග්නිටියුඩ් 5.7 හි පවතිනු ඇත.  වස්තූන් දෙකම මේෂ රාශියේ පිහිටා ඇත.",
    date: "September 15, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "15",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-15.jpg",
    toolTip: "© starwalk.space",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },

  {
    title: "Conjunction of the Moon and Mars",
    description:
      "The Moon and Mars will share the same right ascension, with the Moon passing 3°36' to the north of Mars. The Moon will be 21 days old. The Moon will be at magnitude -12.0 and Mars will be at mag -0.4. Both objects will lie in the constellation Taurus.",
    sinhalaDescription:
      "චන්ද්‍රයා සහ අඟහරු එකම නිවැරදි ආරෝහණයක් බෙදා ගනු ඇත, චන්ද්‍රයා අඟහරු ග්‍රහයාගෙන් උතුරට 3°36' පසු කරයි.  චන්ද්‍රයා දින 21 ක් වයසැති වනු ඇත.  චන්ද්‍රයා මැග්නිටියුඩ් -12.0 හි ද අඟහරු ග්‍රහයා -0.4 ද වේ.  වස්තු දෙකම ටෝරස් තාරකා මණ්ඩලයේ පිහිටා ඇත.",
    date: "September 17, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "17",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-17.jpg",
    toolTip: "© cdn.mos.cms.futurecdn.net",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "The Moon at apogee",
    description:
      "The Moon will reach the furthest point along its orbit to the Earth and will appear slightly smaller than at other times. The Moon's distance from the Earth varies because its orbit is not perfectly circular - it is slightly oval-shaped, tracing out a path called an ellipse. As the Moon traverses this elliptical path around the Earth each month, its distance varies by 14%, between 356,500 km at perigee (closest approach to the Earth) and 406,700 km at apogee (furthest from the Earth). As the apogee of 19 September 2022 will occur close to the time of new moon, the moon will appear as no more than a thin crescent.",
    sinhalaDescription:
      "චන්ද්‍රයා පෘථිවිය දෙසට ගමන් කරන කක්ෂය දිගේ දුරම ස්ථානයට ළඟා වන අතර අනෙක් අවස්ථාවන්ට වඩා තරමක් කුඩාව දිස්වනු ඇත.  පෘථිවියේ සිට චන්ද්‍රයාගේ දුර වෙනස් වන්නේ එහි කක්ෂය පරිපූර්‍ණව වෘත්තාකාර නොවන බැවිනි - එය තරමක් ඕවලාකාර හැඩයෙන් යුක්ත වන අතර ඉලිප්සයක් ලෙස හඳුන්වන මාර්ගයක් සොයා ගනී.  සඳ සෑම මසකම පෘථිවිය වටා මෙම ඉලිප්සාකාර මාර්ගය හරහා ගමන් කරන විට, එහි දුර 14% කින් වෙනස් වේ, perigee (පෘථිවියට ආසන්නම ප්‍රවේශය) 356,500 km සහ apogee (පෘථිවියට වඩා දුරින්) 406,700 km අතර වේ.  2022 සැප්තැම්බර් 19 වන දින අපොජිය නව සඳ ඇති වේලාවට ආසන්නව සිදුවනු ඇති බැවින්, සඳ සිහින් අඩ සඳකට වඩා නොපෙන්වයි.",
    date: "September 19, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "19",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-19.jpg",
    toolTip: "© newportthisweek.com",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Soyuz : ISS 68S",
    description:
      "A Russian government Soyuz rocket will launch the crewed Soyuz MS-22 spacecraft to the International Space Station with the next team of three cosmonauts and astronauts to live and work on the complex. The crew is led by commander Sergey Prokopyev, who will be joined by Russian flight engineer Dmitry Petelin and NASA astronaut Frank Rubio. The rocket will fly in the Soyuz-2.1a configuration.",
    sinhalaDescription:
      "රුසියානු රජයේ Soyuz රොකට්ටුවක් විසින් කාර්ය මණ්ඩලය සහිත Soyuz MS-22 අභ්‍යවකාශ යානය ජාත්‍යන්තර අභ්‍යවකාශ මධ්‍යස්ථානය වෙත අභ්‍යවකාශගාමීන් සහ ගගනගාමීන් තිදෙනෙකුගෙන් යුත් මීළඟ කණ්ඩායම සමඟ සංකීර්ණයේ ජීවත් වීමට සහ වැඩ කිරීමට දියත් කරනු ඇත. කාර්ය මණ්ඩලය මෙහෙයවනු ලබන්නේ කමාන්ඩර් සර්ජි ප්‍රොකොපියෙව් විසින් වන අතර රුසියානු ගුවන් ඉංජිනේරු ඩිමිත්‍රි පෙටලින් සහ නාසා ගගනගාමී ෆ්‍රෑන්ක් රුබියෝ ද ඊට එක් වෙති. රොකට්ටුව Soyuz-2.1a වින්‍යාසය තුළ පියාසර කරනු ඇත.",
    date: "September 21, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "21",
    icon: "astronaut", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-21.jpg",
    toolTip: "© wikimedia.org",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "September equinox",
    description:
      "The September equinox marks the first day of autumn for anybody living in the northern hemisphere, and the first day of spring for anybody living in the southern hemisphere.",
    sinhalaDescription:
      "සැප්තැම්බර් විෂුවය උතුරු අර්ධගෝලයේ ජීවත්වන ඕනෑම කෙනෙකුට සරත් සෘතුවේ පළමු දිනය ද, දකුණු අර්ධගෝලයේ ජීවත් වන ඕනෑම කෙනෙකුට වසන්තයේ පළමු දිනය ද සනිටුහන් කරයි.",
    date: "September 23, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "23",
    icon: "solar", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-23-1.png",
    toolTip: "© wikimedia.org",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Mercury at inferior solar conjunction",
    description:
      "Mercury will pass very close to the Sun in the sky as its orbit carries it between the Sun and Earth. This occurs once in every synodic cycle of the planet (116 days), and marks the end of Mercury's apparition in the evening sky and its transition to become a morning object over the next few weeks.",
    sinhalaDescription:
      "බුධ ග්‍රහයා සූර්යයා හා පෘථිවිය අතර කක්ෂය රැගෙන යන බැවින් අහසේ සූර්යයාට ඉතා ආසන්නයෙන් ගමන් කරනු ඇත.  මෙය ග්‍රහලෝකයේ සෑම සිනොඩික් චක්‍රයකම (දින 116) වරක් සිදු වන අතර, බුධ ග්‍රහයා සවස් වරුවේ අහසේ පෙනීම සහ ඉදිරි සති කිහිපය තුළ උදෑසන වස්තුවක් බවට පත්වීමේ අවසානය සනිටුහන් කරයි.",
    date: "September 23, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "23",
    icon: "solar", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-23-2.jpg",
    toolTip: "© gulf-times.com",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "New Moon",
    description:
      "The Moon will pass close to the Sun and become lost in the its glare for a few days. The Moon's orbital motion carries it around the Earth once every four weeks, and as a result its phases cycle from new moon, through first quarter, full moon and last quarter, back to new moon once every 29.5 days.",
    sinhalaDescription:
      "සඳ සූර්යයාට සමීපව ගමන් කරන අතර දින කිහිපයක් එහි දීප්තියට හසු වනු ඇත.  චන්ද්‍රයාගේ කක්ෂීය චලිතය සෑම සති හතරකට වරක් පෘථිවිය වටා ගෙන යන අතර එහි ප්‍රතිඵලයක් ලෙස එහි අදියර නව සඳ සිට පළමු කාර්තුව, පුර සඳ සහ අවසාන කාර්තුව දක්වා දින 29.5කට වරක් නැවත නව සඳ දක්වා ගමන් කරයි.",
    date: "September 26, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "26",
    icon: "moon", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-26.jpg",
    toolTip: "© in-the-sky.org",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Jupiter at opposition",
    description:
      "Jupiter will reach opposition, when it lies opposite to the Sun in the sky. Lying in the constellation Pisces, it will be visible for much of the night, reaching its highest point in the sky around midnight local time.",
    sinhalaDescription:
      "බ්‍රහස්පති ග්‍රහයා ප්‍රතිවිරෝධයට ළඟා වනු ඇත, එය සූර්යයාට ප්‍රතිවිරුද්ධව අහසේ පිහිටා ඇත.  මීන රාශියේ වැතිර සිටින එය රාත්‍රියේ වැඩි කාලයක් දෘශ්‍යමාන වන අතර දේශීය වේලාවෙන් මධ්‍යම රාත්‍රිය පමණ වන විට එහි ඉහළම ස්ථානයට ළඟා වේ.",
    date: "September 27, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "27",
    icon: "solar", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-27-1.png",
    toolTip: "© earthsky.org",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Daytime Sextantid meteor shower",
    description:
      "The Daytime Sextantid meteor shower will be active from 9 September to 9 October, producing its peak rate of meteors around 27 September.",
    sinhalaDescription:
      "Daytime Sextantid උල්කාපාත වර්ෂාව සැප්තැම්බර් 9 සිට ඔක්තෝබර් 9 දක්වා සක්‍රිය වන අතර සැප්තැම්බර් 27 දින පමණ එහි උපරිම සක්‍රියතාවයට පත් වේ.",
    date: "September 27, 2022",
    langTags: "English, සිංහල",
    month: "September",
    date1: "27",
    icon: "falling-star", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-27-2.jpg",
    toolTip: "© imo.net",
    category: "ASTRONOMY", // ASTRONOMY , SPACE_TECH
  },

  {
    title: "Falcon 9 : SDA Tranche 0",
    description:
      "A SpaceX Falcon 9 rocket will launch 14 Tranche 0 demonstration satellites for the U.S. military's Space Development Agency. The launch is the first of two Falcon 9 missions to carry SDA demonstration spacecraft for a future constellation of military missile tracking and data relay satellites.",
    sinhalaDescription:
      "SpaceX Falcon 9 රොකට්ටුවක් මගින් එක්සත් ජනපද හමුදාවේ අභ්‍යවකාශ සංවර්ධන ඒජන්සිය සඳහා ට්‍රාන්චේ 0 ආදර්ශන චන්ද්‍රිකා 14ක් දියත් කරනු ඇත. මෙම දියත් කිරීම අනාගත හමුදා මිසයිල ලුහුබැඳීම් සහ දත්ත රිලේ චන්ද්‍රිකා සමූහයක් සඳහා SDA ආදර්ශන අභ්‍යවකාශ යානා රැගෙන යන Falcon 9 මෙහෙයුම් දෙකෙන් පළමුවැන්නයි.",
    date: "September: TBD",
    langTags: "English, සිංහල",
    month: "September",
    date1: "TBD",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-tbd-1.jpg",
    toolTip: "© space.skyrocket.de",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: "Atlas 5 : SES 20 & SES 21",
    description:
      "A United Launch Alliance Atlas 5 rocket will launch the SES 20 and SES 21 communications satellites for SES of Luxembourg. SES 20 and 21 will provide C-band television and data services over the United States. The rocket will fly in the 531 vehicle configuration with a five-meter fairing, three solid rocket boosters, and a single-engine Centaur upper stage. Delayed from August.",
    sinhalaDescription:
      "United Launch Alliance Atlas 5 රොකට්ටුවක් මගින් ලක්සම්බර්ග් හි SES සඳහා SES 20 සහ SES 21 සන්නිවේදන චන්ද්‍රිකා දියත් කරනු ඇත. SES 20 සහ 21 එක්සත් ජනපදය හරහා C-band රූපවාහිනී සහ දත්ත සේවා සපයනු ඇත. රොකට්ටුව 531 වාහන වින්‍යාසය තුළ මීටර් පහක පොළක්, ඝන රොකට් බූස්ටර තුනක් සහ තනි එන්ජිමක් සහිත සෙන්ටෝර් ඉහළ වේදිකාවක් සමඟ පියාසර කරනු ඇත. අගෝස්තු සිට ප්‍රමාදයි.",
    date: "September: TBD",
    langTags: "English, සිංහල",
    month: "September",
    date1: "TBD",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/sep-tbd-2.jpg",
    toolTip: "© space.skyrocket.de",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
  {
    title: " Terran 1 : The world's largest 3D printed object, into space",
    description:
      "In 8th March 2023, the Relativity Space Terran 1 rocket lit up the night sky as it launched from Cape Canaveral Space Force Station in Florida. This was the first launch of a test rocket made entirely from 3D-printed parts, measuring 100 feet tall and 7.5 feet wide.A form of additive manufacturing, 3D printing is a key technology for enhancing capabilities and reducing cost. Terran 1 included nine additively manufactured engines made of an innovative copper alloy, which experienced temperatures approaching 6,000 degrees Fahrenheit.",
    sinhalaDescription:
    "2023 මාර්තු 8 වන දින, ෆ්ලොරිඩාවේ කේප් කැනවෙරල් අභ්‍යවකාශ බලකා මධ්‍යස්ථානයෙන් දියත් කරන ලද සාපේක්ෂතා අභ්‍යවකාශ ටෙරන් 1 රොකට්ටුව රාත්‍රී අහස ආලෝකමත් කළේය. අඩි 100ක් උස සහ අඩි 7.5ක් පළලින් සම්පූර්ණයෙන් ත්‍රිමාණ මුද්‍රිත කොටස් වලින් සාදන ලද පරීක්ෂණ රොකට්ටුවක පළමු දියත් කිරීම මෙය විය. ආකලන නිෂ්පාදන ක්‍රමයක් වන ත්‍රිමාණ මුද්‍රණය හැකියාවන් වැඩි කිරීමට සහ පිරිවැය අඩු කිරීමට ප්‍රධාන තාක්‍ෂණයකි. ටෙරාන් 1 හි නව්‍ය තඹ මිශ්‍ර ලෝහයකින් සාදන ලද ආකලන ලෙස නිපදවන ලද එන්ජින් නවයක් ඇතුළත් වූ අතර එය ෆැරන්හයිට් අංශක 6,000 කට වුවද ඔරොත්තු දීමට හැකියාව ඇත.",
    date: "March 08, 2023",
    langTags: "English, සිංහල",
    month: "September",
    date1: "TBD",
    icon: "rocket", //falling-star, rocket, astronaut, clock, solar, moon
    image: "./images/insights/mar-08-23.png",
    toolTip: "© space.skyrocket.de",
    category: "SPACE_TECH", // ASTRONOMY , SPACE_TECH
  },
];
