import React, { useState, useEffect, useRef } from "react";
import { GiFallingStar, GiAstronautHelmet } from "react-icons/gi";
import { FaRocket } from "react-icons/fa";
import { BsFillClockFill } from "react-icons/bs";
import { GiSolarSystem } from "react-icons/gi";
import { WiMoonAltWaxingCrescent6 } from "react-icons/wi";
import "./InsightListItem.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

const InsightListItem = ({
  title,
  description,
  sinhalaDescription,
  langTags,
  date,
  icon,
  bgColour,
  image,
  toolTip,
  category,
}) => {
  const shortDes = description;
  const itemCategory = category.toLowerCase();
  const chipLabel = () => {
    if (itemCategory === "space_tech") {
      return "Space Technology";
    } else {
      return "Astronomy";
    }
  };

  const [selectedDes, setSelectedDes] = useState(description);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const setIcon = () => {
    switch (icon) {
      case "rocket":
        return <FaRocket />;

      case "falling-star":
        return <GiFallingStar />;

      case "astronaut":
        return <GiAstronautHelmet />;

      case "solar":
        return <GiSolarSystem />;

      case "moon":
        return <WiMoonAltWaxingCrescent6 />;

      default:
        return <BsFillClockFill />;
    }
  };

  return (
    <div className="insight-list-item" data-aos="fade-right">
      {/* Top area --------------------------------------------------- */}
      <div className="insight-item-top-area">
        <div className="insight-item-date">{date}</div>
        <Divider className="top-area-devider" />
      </div>
      <Accordion
        expanded={expanded === "panel"}
        onChange={handleChange("panel")}
        className="inisght-accordion"
      >
        {/* Header area ----------------------------------------------- */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panelbh-content"
          id="panelbh-header"
          className={
            expanded
              ? `inisght-accordion-expanded-header-${itemCategory}`
              : `inisght-accordion-header-${itemCategory}`
          }
        >
          <div className="insight-header-flex-div">
            {!expanded ? (
              <div className="insight-item-header-image-container">
                <img
                  src={image}
                  alt={title}
                  className="insight-item-header-image"
                ></img>
              </div>
            ) : (
              <div></div>
            )}
            <div className="header-details-area">
              <div className="header-title">{title}</div>
              {!expanded ? (
                <div>
                  <div className="header-subtitle-row">
                    <div className="header-icon">{setIcon()}</div>
                    <Chip
                      label={chipLabel()}
                      variant="outlined"
                      className="header-chip"
                    />
                    <Chip
                      label={langTags}
                      variant="outlined"
                      className="header-chip"
                    />
                  </div>
                  <Divider />
                  <div>
                    <div className="header-description">
                      {shortDes.substr(0, shortDes.indexOf("."))}{" "}
                      <b>... See More </b>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </AccordionSummary>
        {/* Description area ---------------------------------------------------- */}
        <AccordionDetails>
          <div className="insight-item-details-container">
            <div className="insight-item-details-image-container">
              <img
                src={image}
                alt={title}
                title={`${toolTip}`}
                className="insight-item-description-image"
              ></img>
            </div>
            <div className={`insight-item-details-area-${itemCategory}`}>
              <div className="description-lang-row">
                <div onClick={() => setSelectedDes(description)}>
                  <Chip
                    label={"English"}
                    variant="outlined"
                    className="description-lang-chip"
                    clickable={true}
                  />
                </div>
                <div onClick={() => setSelectedDes(sinhalaDescription)}>
                  <Chip
                    label={"සිංහල"}
                    variant="outlined"
                    className="description-lang-chip"
                    clickable={true}
                  />
                </div>
              </div>
              <div className="insight-item-details-description">
                {selectedDes}
              </div>
              <Divider className="description-devider" />
              <div className="description-subtitle-row">
                <div className="description-icon">{setIcon()}</div>
                <Chip
                  label={chipLabel()}
                  variant="outlined"
                  className="description-chip"
                />
                <Chip
                  label={date}
                  variant="outlined"
                  className="description-chip"
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default InsightListItem;
