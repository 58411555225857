import React from "react";
import { Fade } from "react-awesome-reveal";
import UpcomingEvents from "../components/EventComponents/UpcomingEvents";
import EventFilter from "../components/EventComponents/EventFilter";
import "./EventPage.scss";

const Eventpage = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 0.8,
    },
  };

  const pageTransitions = {
    type: "linear",
    ease: "anticipate",
    duration: 1,
  };

  const upcomingEventsData = [
    {
      image: "/images/events/huntbeyound/HB02.jpg",
      title: "Inspiration 04 - Live Web Cast",
      date: "2021-09-15",
      time: "5.30 AM",
      platform: "Zoom",
      desc: "“Be an observer while everyone else just sees”. Competition was successfully concluded on 26th May, 2020",
    },
    {
      image: "/images/events/huntbeyound/HB03.jpg",
      title: "Inspiration 04 - Live Web Cast",
      date: "2021-09-15",
      time: "5.30 AM",
      platform: "Zoom",
      desc: "“Be an observer while everyone else just sees”. Competition was successfully concluded on 26th May, 2020",
    },
  ];

  return (
    <div
      className="Events"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransitions}
    >
      <div className="blur-layer  events-blur-layer">
        <div className="main-title events" data-aos="fade-right">
          Events
        </div>

        <div className="event-description" data-aos="fade-left">
          With aim of offering you the best knowledge and precious experience to
          explore more about the puzzling universe SEDS Mora comes up numerous
          events throughout the year including webinar and discussion sessions,
          QnA platforms, practical workshops, observation camps along with
          national and international competitions. With such events you are
          given the exposure you are seeking as an enthusiastic individual about
          this universe. All most of all of those events are organised
          collaborating with expertise in the field just to ensure the high
          quality of the work.{" "}
        </div>

        {/* --------- Upcoming Events --------- */}
        {/* <div className="container-events container-1">
          <div className="sub-title-events">Upcoming Events</div>
          <div className="container-upcoming-events">
            {upcomingEventsData.map((data) => {
              return (
                <Fade left>
                  <UpcomingEvents {...data} />
                </Fade>
              );
            })}
          </div>
        </div> */}

        {/* --------- Events Board --------- */}
        <div className="container-events container-2">
          <div className="sub-title-events">Previous Events</div>
          <EventFilter />
        </div>
      </div>
    </div>
  );
};

export default Eventpage;
