import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { Button } from "../button/Button";
import "./Navbar.scss";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const checkScrolled = () => {
    if (
      document.body.scrollTop > 10 ||
      document.documentElement.scrollTop > 10
    ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener("resize", showButton);
  window.addEventListener("scroll", checkScrolled, { passive: true });

  return (
    <>
      <div className={scrolled || click ? "navbar navbar-shadow" : "navbar"}>
        <div className="navbar-container ">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            SEDS Mora
          </Link>
          <div className="navbar-menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <div className={click ? "navbar-menu active" : "navbar-menu"}>
            <ul>
              <li className="navbar-item">
                <NavLink
                  to="/insights"
                  className="navbar-links"
                  onClick={closeMobileMenu}
                >
                  INSIGHTS
                </NavLink>
              </li>
              <li className="navbar-item">
                <NavLink
                  to="/events"
                  className="navbar-links"
                  onClick={closeMobileMenu}
                >
                  EVENTS
                </NavLink>
              </li>
              {/* <li className="navbar-item">
              <NavLink to='/blogs' className="navbar-links" onClick={closeMobileMenu}>
                BLOG
              </NavLink>
            </li> */}
              <li className="navbar-item">
                <NavLink
                  to="/about"
                  className="navbar-links"
                  onClick={closeMobileMenu}
                >
                  ABOUT
                </NavLink>
              </li>
              <li className="navbar-item">
                <NavLink
                  to="/team"
                  className="navbar-links"
                  onClick={closeMobileMenu}
                >
                  TEAM
                </NavLink>
              </li>
              <li className="navbar-item">
                <Link
                  to="/contactus"
                  className="btn-link"
                  onClick={closeMobileMenu}
                >
                  <Button
                    buttonStyle="btn--outline"
                    buttonSize="btn--medium"
                    buttonColor="red"
                  >
                    CONTACT US
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
