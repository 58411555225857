export const ExTeam = [
  {
    avatarImg: "./images/team/executive/Dilan Senuruk_President.jpg",
    imgAlt: "Dilan Senuruk_President.jpg",
    designation: "President",
    name: "Dilan Senuruk",
    linkedIn: "#",
    email: "dilansenuruk@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Upadya Prabhashini_Secretary.jpg",
    imgAlt: "Upadya Prabhashini_Secretary.jpg",
    designation: "Secretary",
    name: "Upadya Prabhashini Ruwanpura",
    linkedIn: "#",
    email: "upadya.ruwanpura2000@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Pubudu_Dissanayake_-_Vice_president.jpg",
    imgAlt: "Pubudu_Dissanayake_-_Vice_president.jpg",
    designation: "Vice President",
    name: "Pubudu Dissanayake",
    linkedIn: "#",
    email: "pubs98me@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Nonimi Aloka _vice secretary_.jpg",
    imgAlt: "Nonimi Aloka _vice secretary_.jpg",
    designation: "Vice Secretary",
    name: "Nonimi Aloka",
    linkedIn: "#",
    email: "wathurupathawana.20@uom.lk",
    faculty: "Faculty of Engineering",
    batch: "20",
  },

  {
    avatarImg: "./images/team/executive/Priyan_Treasurer.jpg",
    imgAlt: "Priyan_Treasurer.jpg",
    designation: "Treasurer",
    name: "Priyan Dilshan",
    linkedIn: "#",
    email: "priyan.dilshan.2@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Lathika_wathsara_-_Membershi_developmemt_manager.jpg",
    imgAlt: "Lathika_wathsara_-_Membership_developmemt_manager.jpg",
    designation: "Membership Development Manager",
    name: "Lathika Wathsara",
    linkedIn: "#",
    email: "lathwath5@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Bimsara Perera_Project Manager.jpeg",
    imgAlt: "Bimsara Perera_Project Manager.jpeg",
    designation: "Project Manager",
    name: "Bimsara Perera",
    linkedIn: "#",
    email: "bimsaraperera00@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Dilshan Jayawickrama_Project Manager.jpeg",
    imgAlt: "Dilshan Jayawickrama_Project Manager.jpeg",
    designation: "Project Manager",
    name: "Dilshan Jayawickrama ",
    linkedIn: "#",
    email: "dilshanjayawickrama2@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Themira Chathumina - Creative Design Manager.jpg",
    imgAlt: "Themira Chathumina - Creative Design Manager.jpg",
    designation: "Creative Design Manager",
    name: "Themira Chathumina",
    linkedIn: "#",
    email: "chathuminathemira@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },

  {
    avatarImg: "./images/team/executive/Dihansa Perera_Editorial Manager.jpg",
    imgAlt: "Dihansa Perera_Editorial Manager.jpg",
    designation: "Editorial Manager",
    name: "Dihansa Perera",
    linkedIn: "#",
    email: "nishiniperera57@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "19",
  },

  {
    avatarImg: "./images/team/executive/Dulaj Bhagya_Web and technology manager.png",
    imgAlt: "Dulaj Bhagya_Web and technology manager.png",
    designation: "Web & Technology Manager",
    name: "Dulaj Bhagya",
    linkedIn: "https://www.linkedin.com/in/dulajbhagya/",
    email: "dulajupananda@gmail.com",
    facebook: "https://www.facebook.com/profile.php?id=100005369356450",
    faculty: "Faculty of Information Technology",
    batch: "20",
  },
  {
    avatarImg: "./images/team/executive/Yasiru Senarath Karunanayaka_Partnership Development Manager.jpg",
    imgAlt: "Yasiru Senarath Karunanayaka_Partnership Development Manager.jpg",
    designation: "Partnership Development Manager",
    name: "Yasiru Senarath",
    linkedIn: "#",
    email: "#",
    faculty: "Faculty of Engineering",
    batch: "19",
  }
];

export const LwTeam = [
  {
    avatarImg: "./images/team/committee/Ramajini_Committee.jpeg",
    imgAlt: "Ramajini Gnanasuthan",
    designation: "Committee",
    name: "Ramajini Gnanasuthan",
    linkedIn: "#",
    email: "ramajinignanasuthan@gmail.com",
    faculty: "Faculty of Information Technology",
    batch: "21",
  },
  {
    avatarImg: "./images/team/committee/Sandalika Udani_ Committee_.jpg",
    imgAlt: "Sandalika Udani",
    designation: "Committee",
    name: "Sandalika Udani",
    linkedIn: "#",
    email: "sandalihpaa@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Yasiru Jayasinghe_Committee.jpg",
    imgAlt: "Yasiru Jayasinghe",
    designation: "Committee",
    name: "Yasiru Jayasinghe",
    linkedIn: "https://www.linkedin.com/in/yasiru-jayasinghe-50978a276/",
    email: "yasirujayasinghe129@gmail.com",
    faculty: "Faculty of Information Technology",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Thimali Dananjaya _Committee .jpg",
    imgAlt: "Thimali Dananjaya",
    designation: "Committee",
    name: "Thimali Dananjaya",
    linkedIn: "#",
    email: "thimalinayanathara@gmail.com",
    faculty: "Faculty of Architecture",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Thathindi Nayanavee_committee.jpg",
    imgAlt: "Thathindi Nayanavee",
    designation: "Committee",
    name: "Thathindi Nayanavee",
    linkedIn: "#",
    email: "thathindinayanavee001@gmail.com",
    faculty: "Faculty of Medicine",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Anupa Perera_committee.jpg",
    imgAlt: "Anupa Perera",
    designation: "Committee",
    name: "Anupa Perera",
    linkedIn: "#",
    email: "anupaperera2000@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Tharusha Sihan_committe.JPG",
    imgAlt: "Tharusha Sihan",
    designation: "Committee",
    name: "Tharusha Sihan",
    linkedIn: "#",
    email: "tharusha.sihan629@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Thushan Chamika - Committe Member.jpg",
    imgAlt: "Thushan Chamika",
    designation: "Committee",
    name: "Thushan Chamika",
    linkedIn: "#",
    email: "thushanch78@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Buddhini Malindika_committee.jpg",
    imgAlt: "Buddhini Malindika",
    designation: "Committee",
    name: "Buddhini Malindika",
    linkedIn: "#",
    email: "buddhinimalindika123@gmail.com",
    faculty: "Institute of Technology (NDT)",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Anuda Wewalage_committee.jpg",
    imgAlt: "Anuda Wewalage",
    designation: "Committee",
    name: "Anuda Wewalage",
    linkedIn: "#",
    email: "anudawewalage@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "21",
  },
  {
    avatarImg: "./images/team/committee/Anupa Alwis_Committee.jpg",
    imgAlt: "Anupa Alwis",
    designation: "Committee",
    name: "Anupa Alwis",
    linkedIn: "#",
    email: "isurajithalwis@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "21",
  },
  {
    avatarImg: "./images/team/committee/Manishi Prabasha_committee.jpg",
    imgAlt: "P. A. Manishi Prabasha",
    designation: "Committee",
    name: "P. A. Manishi Prabasha",
    linkedIn: "#",
    email: "manishiprabasha@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "21",
  },
  {
    avatarImg: "./images/team/committee/Chamith Eshan_Committe.jpg",
    imgAlt: "Sasindu Jayasri",
    designation: "Committee",
    name: "Sasindu Jayasri",
    linkedIn: "#",
    email: "sasindujayasri99@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },
  {
    avatarImg: "./images/team/committee/Thisura Damsika_committee.jpg",
    imgAlt: "Thisura Ekanayake",
    designation: "Committee",
    name: "Thisura Ekanayake",
    linkedIn: "#",
    email: "thisuradamsika2001@gmail.com",
    faculty: "Faculty of Medicine",
    batch: "21",
  },
  {
    avatarImg: "./images/team/committee/Sasindu Jayasri_committee.jpg",
    imgAlt: "Sasindu Jayasri",
    designation: "Committee",
    name: "Sasindu Jayasri",
    linkedIn: "#",
    email: "sasindujayasri99@gmail.com",
    faculty: "Faculty of Engineering",
    batch: "20",
  },
  
];
