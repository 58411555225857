import React from "react";
import { SliderData } from "./SliderData";
import "./SliderGuides.scss";

const SliderGuides = ({ current, changeCurrent }) => {
  return (
    <div className="slider-guide-container">
      {SliderData.map((slide, index) => {
        return (
          <div
            key={index}
            className={
              index === current ? "slider-guide-dot active" : "slider-guide-dot"
            }
            onClick={() => {
              changeCurrent(index);
            }}
          ></div>
        );
      })}
    </div>
  );
};

export default SliderGuides;
