export const CardData = [
  {
    id: 1,
    small: false,
    medium: true,
    large: false,
    year: 2019,
    category: ["physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "ExploreX",
    link: "explorex",
    title: "ExploreX",
    description: `The initial step of the astronomical session series "ExploreX"  was successfully completed on 03rd of April 2019, ......`,
    longDescription: `The initial step of the astronomical session series "ExploreX"  was successfully completed on 03rd of April 2019, in collaboration with Arthur C. Clarke Institute. Through this session, students were given the opportunity to witness the largest telescope in Sri Lanka and also they were lucky enough to observe the sun using a telescope. SEDS Mora would like to extend a huge thanks to everyone out there who assisted us to make this event a success!`,
    imageLink: "/images/events/expolorex/EX01.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/expolorex/EX01.jpg",
      "/images/events/expolorex/EX02.jpg",
      "/images/events/expolorex/EX03.jpg",
      "/images/events/expolorex/EX04.jpg",
      "/images/events/expolorex/EX05.jpg",
      "/images/events/expolorex/EX06.jpg",
      "/images/events/expolorex/EX07.jpg",
      "/images/events/expolorex/EX08.jpg",
      "/images/events/expolorex/EX09.jpg",
    ],
  },

  {
    id: 2,
    small: false,
    medium: true,
    large: false,
    year: 2019,
    category: ["physical", "competition"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "AquaRo",
    link: "aquaro",
    title: "AquaRo",
    description: `First and foremost competition on designing an automated distance calculating device for water rockets was successfully, ......`,
    longDescription: `First and foremost competition on designing an automated distance calculating device for water rockets was successfully carried out on 9th September 2019. Many thanks to all of you who were with us from beginning to end in order to make this endeavor more successful. Congratulations to the team Rocket X who designed the highest accurate distance measurement device for water rockets.`,
    imageLink: "/images/events/aquaro/AQ01.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/aquaro/AQ01.jpg",
      "/images/events/aquaro/AQ02.jpg",
      "/images/events/aquaro/AQ03.jpg",
      "/images/events/aquaro/AQ04.jpg",
      "/images/events/aquaro/AQ05.jpg",
      "/images/events/aquaro/AQ06.jpg",
      "/images/events/aquaro/AQ07.jpg",
      "/images/events/aquaro/AQ08.jpg",
      "/images/events/aquaro/AQ09.jpg",
      "/images/events/aquaro/AQ10.jpg",
      "/images/events/aquaro/AQ11.jpg",
      "/images/events/aquaro/AQ12.jpg",
      "/images/events/aquaro/AQ13.jpg",
      "/images/events/aquaro/AQ14.jpg",
      "/images/events/aquaro/AQ15.jpg",
      "/images/events/aquaro/AQ16.jpg",
      "/images/events/aquaro/AQ17.jpg",
      "/images/events/aquaro/AQ18.jpg",
    ],
  },

  {
    id: 3,
    small: false,
    medium: true,
    large: false,
    year: 2019,
    category: ["physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Eclipse 2019",
    link: "eclipse2019",
    title: "Eclipse 2019",
    description:
      "We have successfully completed the most exciting astronomical event of the . . .",
    longDescription: `We have successfully completed the most exciting astronomical event of the year 2019. Special thanks go to Arthur C. Clarke Institute for Modern Technologies for your guidance and instruments. Thank you for joining us to witness one of the rarest events which happen once in a few years as well we believe it has been a great experience for the participants in their lives.
    `,
    imageLink: "/images/events/eclipse19/EC10.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/eclipse19/EC01.jpg",
      "/images/events/eclipse19/EC02.jpg",
      "/images/events/eclipse19/EC03.jpg",
      "/images/events/eclipse19/EC04.jpg",
      "/images/events/eclipse19/EC05.jpg",
      "/images/events/eclipse19/EC06.jpg",
      "/images/events/eclipse19/EC07.jpg",
      "/images/events/eclipse19/EC08.jpg",
      "/images/events/eclipse19/EC09.jpg",
      "/images/events/eclipse19/EC10.jpg",
      "/images/events/eclipse19/EC11.jpg",
      "/images/events/eclipse19/EC12.jpg",
      "/images/events/eclipse19/EC13.jpg",
      "/images/events/eclipse19/EC14.jpg",
      "/images/events/eclipse19/EC15.jpg",
    ],
  },

  {
    id: 4,
    small: false,
    medium: true,
    large: false,
    year: 2019,
    category: ["physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Gankanda Cental College | Night Camp",
    link: "nightcamp",
    title: "Night Camp",
    description:
      "With more than 150 school students’ participation a  night sky observation . . .",
    longDescription: `With more than 150 school students’ participation a  night sky observation camp was conducted by SEDS Mora at the Gankanda Central College in Palmadulla. `,
    imageLink: "/images/events/nightcamp/GN17.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/nightcamp/GN01.jpg",
      "/images/events/nightcamp/GN02.jpg",
      "/images/events/nightcamp/GN03.jpg",
      "/images/events/nightcamp/GN04.jpg",
      "/images/events/nightcamp/GN05.jpg",
      "/images/events/nightcamp/GN06.jpg",
      "/images/events/nightcamp/GN07.jpg",
      "/images/events/nightcamp/GN08.jpg",
      "/images/events/nightcamp/GN09.jpg",
      "/images/events/nightcamp/GN10.jpg",
      "/images/events/nightcamp/GN11.jpg",
      "/images/events/nightcamp/GN12.jpg",
      "/images/events/nightcamp/GN13.jpg",
      "/images/events/nightcamp/GN14.jpg",
      "/images/events/nightcamp/GN15.jpg",
      "/images/events/nightcamp/GN16.jpg",
      "/images/events/nightcamp/GN17.jpg",
      "/images/events/nightcamp/GN18.jpg",
      "/images/events/nightcamp/GN19.jpg",
      "/images/events/nightcamp/GN20.jpg",
      "/images/events/nightcamp/GN21.jpg",
      "/images/events/nightcamp/GN22.jpg",
      "/images/events/nightcamp/GN23.jpg",
      "/images/events/nightcamp/GN24.jpg",
    ],
  },

  {
    id: 5,
    small: false,
    medium: true,
    large: false,
    year: 2019,
    category: ["physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Discussion Series #01",
    link: "discussions",
    title: "Discussion Series #01",
    description:
      "The interactive discussion session organized by SEDS Mora on topics related to astronomy . . .",
    longDescription: `The interactive discussion session organized by SEDS Mora on topics related to astronomy was successfully held on the 27th of February at university premises from 6.30p.m. onwards. Moving ahead of traditional discussion panels, participants were given the chance of sharing their diverse opinions and arguments with the audience, in a very friendly and interactive manner. 
    `,
    imageLink: "/images/events/discussions01/DS06.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/discussions01/DS01.jpg",
      "/images/events/discussions01/DS02.jpg",
      "/images/events/discussions01/DS03.jpg",
      "/images/events/discussions01/DS04.jpg",
      "/images/events/discussions01/DS05.jpg",
      "/images/events/discussions01/DS06.jpg",
    ],
  },

  {
    id: 6,
    small: false,
    medium: true,
    large: false,
    year: 2019,
    category: ["physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Super Moon",
    link: "supermoon",
    title: "Super Moon",
    description:
      "Supermoon observation session was held at the Bawana, by SEDS Mora. Lot of students . . .",
    longDescription: `Supermoon observation session was held at the Bawana, by SEDS Mora. Lot of students participated in the event, to witness the moon at its closest.`,
    imageLink: "/images/events/supermoon/SM02.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/supermoon/SM01.jpg",
      "/images/events/supermoon/SM03.jpg",
    ],
  },

  {
    id: 7,
    small: false,
    medium: true,
    large: false,
    year: 2020,
    category: ["live", "competition"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Selenophilia 1.0",
    link: "selenophilia",
    title: "Selenophilia 1.0",
    description:
      "Selenophilia 1.0 astro online quiz competition was successfully concluded . . .",
    longDescription: `Selenophilia 1.0 astro online quiz competition was successfully concluded.  Best wishes for Dumindu Madusanka from faculty of Engineering, the winner of the competition.`,

    imageLink: "/images/events/selenophilia/SP03.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/selenophilia/SP01.jpg",
      "/images/events/selenophilia/SP02.jpg",
    ],
  },

  {
    id: 8,
    small: false,
    medium: true,
    large: false,
    year: 2020,
    category: ["live", "competition"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Hunt beyound Earth",
    link: "huntbeyound",
    title: "Hunt beyound Earth",
    description:
      "Hunt beyond earth was a riddle solving competition under the theme of “Be an observer . . . ",
    longDescription: `Hunt beyond earth was a riddle solving competition under the theme of “Be an observer while everyone else just sees”. Competition was successfully concluded on 26th May, 2020 where Pasan Anushanga obtained the first place and became the best observer out of all the participants .`,
    imageLink: "/images/events/huntbeyound/HB01.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/huntbeyound/HB01.jpg",
      "/images/events/huntbeyound/HB02.jpg",
      "/images/events/huntbeyound/HB03.jpg",
      "/images/events/huntbeyound/HB04.jpg",
      "/images/events/huntbeyound/HB05.jpg",
      "/images/events/huntbeyound/HB06.jpg",
    ],
  },

  {
    id: 9,
    small: false,
    medium: true,
    large: false,
    year: 2020,
    category: ["webinar", "live"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Infinity",
    link: "infinity",
    title: "Infinity",
    description:
      "Infinity beyond the limit discussion series was conducted for several episodes with . . . ",
    longDescription: `Infinity beyond the limit discussion series was conducted for several episodes with a high number of participants and with highly appreciated feedback. It covered a wide range of topics from the very basics of astronomy to rocket technology. Special thanks goes to all the panelists of each session for sharing their knowledge with no hesitation.
    `,
    imageLink: "/images/events/infinity/IN01.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/infinity/IN02.jpg",
      "/images/events/infinity/IN03.jpg",
      "/images/events/infinity/IN04.jpg",
    ],
  },

  {
    id: 10,
    small: false,
    medium: true,
    large: false,
    year: 2020,
    category: ["webinar", "live"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Path Finder",
    link: "pathfinder",
    title: "Path Finder",
    description:
      "SEDS Mora | PathFinder is the monthly webinar series conducted by SEDS University of . . . ",
    longDescription: `SEDS Mora | PathFinder is the monthly webinar series conducted by SEDS University of Moratuwa chapter for the space science, space technology and astrology enthusiasts since October 2020. So far, we have completed three webinars in 3 different areas, as mentioned below,
    Webinar on UFOlogy (By Mr Hashan Thilakarathne ), Webinar on Astronomy, Climatology and Earth Science( By Mr Maleen Wijeratna Kidiwela), Webinar on Modified Gravity and Dark Energy  (By Mr Hasitha Eranda)
    Heartfelt gratitude to  all the guest speakers and everyone out there who worked hard to make this event a success.`,
    imageLink: "/images/events/pathfinder/PF01.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/pathfinder/PF02.jpg",
      "/images/events/pathfinder/PF03.jpg",
      "/images/events/pathfinder/PF04.jpg",
    ],
  },

  {
    id: 11,
    small: false,
    medium: true,
    large: false,
    year: 2020,
    category: ["live"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "SpaceXplorers",
    link: "Spacexplorers",
    title: "SpaceXplorers",
    description:
      "SpaceXplorers is the SEDS Mora facebook group which was created with the intention of . . . ",
    longDescription: `SpaceXplorers is the SEDS Mora facebook group which was created with the intention of building a platform to exchange knowledge related to space and space technology with each other with more than 200+ members to the date.`,
    imageLink: "/images/events/spacexplorers/SX02.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/spacexplorers/SX01.jpg",
      "/images/events/spacexplorers/SX02.jpg",
    ],
  },

  {
    id: 12,
    small: false,
    medium: true,
    large: false,
    year: 2021,
    category: ["live", "competition"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Ouranos",
    link: "ouranos",
    title: "Ouranos",
    description:
      "“Ouranos” is the first-ever inter-university photo manipulation competition SEDS . . . ",
    longDescription: `“Ouranos” is the first-ever inter-university photo manipulation competition SEDS University of Moratuwa presents which is targeting all-island university students. It was started on 15th of January via the social media platform under the theme of “The space fantasy from the past to the future of the cosmos”.
    `,
    imageLink: "/images/events/ouranos/OU01.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/ouranos/OU02.jpg",
      "/images/events/ouranos/OU03.jpg",
      "/images/events/ouranos/OU04.jpg",
      "/images/events/ouranos/OU05.jpg",
      "/images/events/ouranos/OU06.jpg",
      "/images/events/ouranos/OU07.jpg",
    ],
  },

  {
    id: 13,
    small: false,
    medium: true,
    large: false,
    year: 2023,
    category: ["live", "physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Star_Junction",
    link: "Star_Junction",
    title: "Star Junction",
    description:"Star Junction was an intellectual gathering where aerospace and astronomy united . . . ",
    longDescription: `"Star Junction" was an intellectual gathering where aerospace and astronomy united, enabling University of Moratuwa undergraduates to engage in captivating discussions about Astronomy and Aerospace. This event served as a unique platform for students to delve into the realms of aerospace and astronomy, fostering knowledge exchange and passionate conversations. Initiated by SEDS Mora, Star Junction commenced its cosmic journey to explore the mysteries of the universe on 27th April 2023.`,
    imageLink: "/images/events/star junction/star_junction.png",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/star junction/sj-1.JPG",
      "/images/events/star junction/sj-2.JPG",
      "/images/events/star junction/sj-3.JPG",
      "/images/events/star junction/sj-4.JPG",
      "/images/events/star junction/sj-5.JPG",
      "/images/events/star junction/sj-6.JPG",
      "/images/events/star junction/sj-7.JPG",
      "/images/events/star junction/sj-8.JPG",
      "/images/events/star junction/sj-9.JPG",
    ],
  },

  {
    id: 14,
    small: false,
    medium: true,
    large: false,
    year: 2023,
    category: ["live", "physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Exmo_2023",
    link: "Exmo_2023",
    title: "EXMO 2023",
    description:
      "“EXMO 2023 is the flagship engineering exhibition organized by . . . ",
    longDescription: `"EXMO 2023" is the flagship engineering exhibition organized by the Faculty of Engineering of University of Moratuwa. SEDS Mora had set up a stall with pride, showcasing a convergence of astronomy. Visitors experienced the wonders of the cosmos and delved into the innovations of aerospace engineering. Our team engaged with attendees, providing a unique fusion of engineering excellence and cosmic exploration.`,
    imageLink: "/images/events/exmo/exmo_2023.jpg",
    imgAlt: "exmo 2023",
    photoLinks: [
      "/images/events/exmo/exmo-1.jpg",
      "/images/events/exmo/exmo-2.jpg",
      "/images/events/exmo/exmo-3.jpg",
      "/images/events/exmo/exmo-4.jpg",
      "/images/events/exmo/exmo-5.jpg",
    ],
  },

  {
    id: 15,
    small: false,
    medium: true,
    large: false,
    year: 2023,
    category: ["live", "webinar"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Cosmic_chronicles",
    link: "Cosmic_chronicles",
    title: "Cosmic Chronicles",
    description:
      "“Cosmic Chronicles is the first-ever inter-university photo manipulation competition SEDS . . . ",
    longDescription: `“Cosmic Chronicles” is the series of webinars on the most aspiring topics related to space and astronomy that has gained the timeline. It was started on 06th of August via the zoom platform under the theme of “The space fantasy from the past to the future of the cosmos”.
    `,
    imageLink: "/images/events/cosmic chronicles/cosmic_chronicles.png",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/cosmic chronicles/cc-1.jpg",
      "/images/events/cosmic chronicles/cc-2.jpg",
      "/images/events/cosmic chronicles/cc-3.jpg",
      "/images/events/cosmic chronicles/cc-4.jpg",
    ],
  },

  {
    id: 16,
    small: false,
    medium: true,
    large: false,
    year: 2023,
    category: ["live", "physical"], //'webinar', 'live', 'physical', 'competition'
    imageTile: "Night_camp_2023",
    link: "Night_camp_2023",
    title: "Night Camp 2023",
    description:
      "SEDS Mora organized a night observation camp at Maputugala Maha Vidyalaya . . . ",
    longDescription: `SEDS Mora organized a night observation camp at Maputugala Maha Vidyalaya, Poruwedanda, on 18th August 2023, to show students the amazing world of astronomy. Participants had the chance to look at the night sky, helping them understand and enjoy the wonders of the stars above.`,
    imageLink: "/images/events/nightcamp_2023/nightcamp_2023.jpg",
    imgAlt: "plant pot",
    photoLinks: [
      "/images/events/nightcamp_2023/nc-1.JPG",
      "/images/events/nightcamp_2023/nc-2.JPG",
      "/images/events/nightcamp_2023/nc-3.JPG",
      "/images/events/nightcamp_2023/nc-4.JPG",
      "/images/events/nightcamp_2023/nc-5.JPG",
    ],
  },
];
