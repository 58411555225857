import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import EventCarouselCard from "./EventCarouselCard";
import { CardData } from "./CardData";
import "./HomeEvents.scss";

export default function HomeEventCarousel() {
  const [isMobile, setIsMobile] = useState(false);
  const [isWide, setIsWide] = useState(false);

  const changeSettings = () => {
    if (window.innerWidth <= 850) {
      setIsMobile(true);
      setIsWide(false);
    } else if (window.innerWidth <= 1200) {
      setIsMobile(false);
      setIsWide(false);
    } else {
      setIsMobile(false);
      setIsWide(true);
    }
  };

  useEffect(() => {
    changeSettings();
    window.addEventListener("resize", changeSettings);
    return () => {
      window.removeEventListener("resize", changeSettings);
    };
  }, []);

  var setWide = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: "ease",
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    swipeToSlide: true,
    centerMode: true,
    class: "carousel",
  };

  var setMobile = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    swipeToSlide: true,
    centerMode: true,
  };

  var setMid = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 3,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: "ease",
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    swipeToSlide: true,
    centerMode: true,
  };

  var settings = isMobile ? setMobile : isWide ? setWide : setMid;

  return (
    <div style={{ width: "100%" }}>
      <Slider {...settings}>
        {CardData.map((slide, index) => {
          return (
            <div key={index}>
              <Link to={`/events/${slide.link}`}>
                <EventCarouselCard {...slide} />
              </Link>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
