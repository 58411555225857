import React from "react";
import "./SliderContent.scss";

const SliderContent = () => {
  return (
    <div className="slider-content">
      {/* <div className="slider-content-title">Wilderness within reach</div> */}
      <div className="slider-content-description">
        "The strongest affection and utmost zeal should, I think, promote the
        studies concerned with the most beautiful objects. <br /> This is the
        discipline that deals with the universe's divine revolutions, the stars'
        motions, sizes, distances, risings and settings . . . for what is more
        beautiful than heaven?"
        <br />
        <br /> — Nicolaus Copernicus, Astronomer
      </div>
      {/* <div className="slider-content-button-section">
        <Button buttonStyle='btn--outline' buttonSize="btn--wide">
          JOIN US
        </Button>
        <Button buttonStyle='btn--outline' buttonSize="btn--wide" buttonColor='grey'>
          OUR SERVICE
        </Button>
      </div> */}
    </div>
  );
};

export default SliderContent;
