import React from "react";
import { motion } from "framer-motion";
import "./Insights.scss";
import InsightListItem from "../components/insightTimeline/InsightListItem";
import { InsightData } from "../components/insightTimeline/InsightData";

const Insights = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 0.8,
    },
  };

  const pageTransitions = {
    type: "linear",
    ease: "anticipate",
    duration: 1,
  };

  const BGCOLOR = ["bg-purple", "bg-blue", "bg-pink", "bg-green"];
  const url1 = "https://in-the-sky.org/newscal.php?year=2021&month=11";
  const url2 = "https://spaceflightnow.com/launch-schedule/";

  return (
    <div
      className="Insights"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransitions}
    >
      <div className="blur-layer insight-blur-layer">
        <div className="insight-timeline">
          <div className="main-title" data-aos="fade-left">
            2024 - February{" "}
          </div>

          <div className="video-title" data-aos="fade-right">
            What's Up This Month
          </div>

          <div className="youtube-video-container" data-aos="fade-left">
            <div className="youtube-frame">
            <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/JxRGCh-p-Ck?si=2JsCgsS9SvAE15Ie" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
            </iframe>
            </div>
          </div>

          <hr style={{ marginTop: "50px" }} />

          <div className="video-title" data-aos="fade-left">
            INSIGHTS
          </div>
          {/* <div className="insight-description">
          Stay tuned with us for new insights
        </div> */}

          {InsightData.map((item, index) => {
            return (
              <InsightListItem
                {...item}
                bgColour={BGCOLOR[index % 4]}
                key={index}
              />
            );
          })}

          <hr style={{ marginTop: "50px" }} />

          <div className="small-text">
            Sources : inthesky.org and spaceflightnow.com
          </div>
          <div className="small-text2">
            Note: Date and Time of the launches can be changed.
            <br />
            TBD: To be determined
            <br />
            NET: No earlier than
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;
