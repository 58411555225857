import React from "react";
import Avatar from "@mui/material/Avatar";
import "./BoardMemberTile.scss";
import { FaEnvelope, FaFacebookSquare } from "react-icons/fa";
import Divider from "@mui/material/Divider";
import { GrLinkedin } from "react-icons/gr";

const BoardMemberTile = ({
  name,
  designation,
  avatarImg,
  linkedIn,
  email,
  facebook,
  faculty,
  batch,
}) => {
  return (
    <div className="member-tile">
      <div
        className="member-avatar-cover"
        style={{ backgroundColor: "#02c59b" }}
      >
        <div className="member-name">{name}</div>
      </div>
      <div className="member-avatar-container">
        <Avatar
          alt="Remy Sharp"
          className="member-avatar"
          style={{ border: "3px solid #02c59b" }}
          src={avatarImg}
          sx={{ width: 100, height: 100 }}
        />
      </div>
      <div className="member-detail-container">
        <div className="member-detail-descripttion">
          {designation !== "" ? (
            <div className="member-designation">{designation}</div>
          ) : (
            <></>
          )}
          {batch !== "" ? (
            <div className="member-batch">Batch {batch}</div>
          ) : (
            <></>
          )}
          {faculty !== "" ? (
            <div className="member-faculty"> {faculty} </div>
          ) : (
            <></>
          )}
        </div>

        {linkedIn !== "" && email !== "" && facebook !== "" ? <Divider /> : <></>}
        <div className="member-social-media">
          {linkedIn !== "" ? (
            <a href={linkedIn} target="_blank" rel="noreferrer">
            <div
              className="member-social-icon"
              style={{ border: "2px solid #02c59b", color: "#02c59b" }}
            >
              <GrLinkedin />
            </div>

            </a>
          ) : (
            <></>
          )}
          {/* {facebook !== "" ? (
            <a href={facebook} target="_blank" rel="noreferrer">
            <div
              className="member-social-icon"
              style={{ border: "2px solid #02c59b", color: "#02c59b" }}
            >
              <FaFacebookSquare  />
            </div>

            </a>
          ) : (
            <></>
          )} */}
          {email !== "" ? (
            <a href="mailto: {email}" target="_blank" rel="noreferrer">
              <div
                className="member-social-icon"
                style={{ border: "2px solid #02c59b", color: "#02c59b" }}
              >
                <FaEnvelope />
              </div>
            </a>
          ) : (
            <></>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default BoardMemberTile;
